

import { Component, Vue } from 'vue-property-decorator';
// import VueLogger from 'vuejs-logger';
// import Vuex, { mapState, mapMutations } from 'vuex'; 

// import HelloWorld from '@/components/HelloWorld.vue'; // @ is an alias to /src
// import B2bCbd from '@/components/B2bCbd.vue'; 
import GrowPotBot from '@/components/GrowPotBot.vue'; 
import FungibleFarm from '@/components/FungibleFarm.vue'; 
import B00T from '@/components/_b00t_.vue'; 
// import CyberStream from '@/components/CyberStream.vue';
//import SpinAVision from '@/components/SpinAVision.vue';


@Component({
  components: {
   // HelloWorld,
    // B2bCbd,
    GrowPotBot,
    FungibleFarm, 
    B00T
  //  CyberStream,
   // SpinAVision
  },
  data: () => {
    return({
      showB2bCbd : false,   // 🍰 can't figure out how to set these from the $route notification.
      showGrowPotBot : false,
      showCyberStream : false,  //
      showSpinAVision : false,
      showB00T : false,
      showFungibleFarm : false
    })
  },
  methods: {
    greet : () => {
    //   alert('hello');
    }
  },
  watch: {
    // how does 'watch' work 🤔 -- but the route itself is in router.ts
    '$route.params.topic'  : {
      handler: function(topic) {
        // Vue.$log.info("got topic: ", topic);
        switch (topic) {
          // case "B2bCbd" : this.$data.showB2bCbd = true; break; 
          case "welcome" : break;
          case "GrowPotBot" : this.$data.showGrowPotBot = true; break;
          // case "CyberStream" : this.$data.showCyberStream = true; break;
          case "_b00t_" : this.$data.showB00T = true; break;
          case "FungibleFarm" : this.$data.showFungibleFarm = true; break;
          default : alert("unknown choice"); break;
        }
      }
    }
  }
  
})
export default class Projects extends Vue {}
